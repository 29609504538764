/* eslint-disable react/button-has-type */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import React, { useCallback, useMemo, useState } from 'react';

import { Body, Icon, VStack } from '@meterup/atto';
import cx from 'classnames';
import TagManager from 'react-gtm-module';
import { useNavigate } from 'react-router-dom';

// Hooks
import useLogEvent, { REQUEST_QUOTE_FORM_SUBMIT_CONTRACT } from '../../hooks/useLogEvent';
import { route } from '../../routes';

interface ContractFormProps {
  sid: string;
}

function ContractForm(props: ContractFormProps) {
  // Router
  const navigate = useNavigate();

  // Event logger:
  const logEvent = useLogEvent();

  const newContractOnClick = useCallback(() => {
    // Log event
    logEvent(REQUEST_QUOTE_FORM_SUBMIT_CONTRACT);

    TagManager.dataLayer({
      dataLayer: {
        event: 'conversion',
        send_to: 'AW-475626357/K-YMCNyXoJcYEPX25eIB',
      },
    });

    // Redirect to submit
    return navigate(route('submit', { sid: props.sid }));
  }, [navigate]);

  const existingContractOnClick = useCallback(() => {
    // Log event
    logEvent(REQUEST_QUOTE_FORM_SUBMIT_CONTRACT);

    TagManager.dataLayer({
      dataLayer: {
        event: 'conversion',
        send_to: 'AW-475626357/K-YMCNyXoJcYEPX25eIB',
      },
    });

    // Redirect to submit
    return navigate(route('topProviders', { sid: props.sid }));
  }, [navigate]);

  const buttonClasses = cx(
    'p-2 w-full rounded-md border border-gray-100 text-left flex items-center',
    'hover:border-gray hover:bg-gray-50',
    'focus:border-brand focus:bg-gray-50 m-focus',
  );

  const [newContractButtonActive, setNewContractButtonActive] = useState<boolean>(false);
  const [existingContractButtonActive, setExistingContractButtonActive] = useState<boolean>(false);

  const newContractButton = useMemo(
    () => (
      <button
        className={buttonClasses}
        onClick={newContractOnClick}
        onMouseOver={() => setNewContractButtonActive(true)}
        onFocus={() => setNewContractButtonActive(true)}
        onMouseOut={() => setNewContractButtonActive(false)}
        onBlur={() => setNewContractButtonActive(false)}
      >
        <div className="w-12 h-12 flex items-start justify-center align-center flex-grow-0 flex-shrink-0 ml-1 mr-3">
          <Icon icon="company" color={{ dark: 'gray200', light: 'gray600' }} size={16} />
        </div>
        <VStack>
          <Body weight="bold">Moving to a new space</Body>
          <Body>Find the best deals across all providers.</Body>
        </VStack>
      </button>
    ),
    [newContractButtonActive],
  );

  const existingContractButton = useMemo(
    () => (
      <button
        className={cx('mt-4', buttonClasses)}
        onClick={existingContractOnClick}
        onMouseOver={() => setExistingContractButtonActive(true)}
        onFocus={() => setExistingContractButtonActive(true)}
        onMouseOut={() => setExistingContractButtonActive(false)}
        onBlur={() => setExistingContractButtonActive(false)}
      >
        <div className="w-10 h-12 flex items-start justify-center align-center flex-grow-0 flex-shrink-0 ml-1 mr-3">
          <Icon icon="document" color={{ dark: 'gray200', light: 'gray600' }} size={16} />
        </div>
        <VStack>
          <Body weight="bold">I want to negotiate an existing contract</Body>
          <Body>Negotiate better rates.</Body>
        </VStack>
      </button>
    ),
    [existingContractButtonActive],
  );

  return (
    <div>
      {newContractButton}
      {existingContractButton}
    </div>
  );
}

export default ContractForm;
