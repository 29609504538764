import React from 'react';

import { FormattedMoney, Icon } from '@meterup/atto';
import { Quote } from '@meterup/connect-api';
import { FormattedMonthlyAmount, FormattedSpeed, ProviderLogo } from '@meterup/connect-ui';
import cx from 'classnames';

import { formatBandwidth } from '../../../utils/formatBandwidth';

interface ConfirmQuoteBlockProps {
  quote: Quote;
}

function ConfirmQuoteBlock({ quote }: ConfirmQuoteBlockProps) {
  let availability: string;
  if (quote.availability && quote.availability !== 'unknown') {
    if (quote.availability === 'location_on_net') {
      availability = 'On-Net';
    } else {
      availability = 'Off-Net';
    }
  }

  const renderHeader = () => (
    <header className="flex justify-between items-center border-b border-gray-100 p-4">
      <div className="h-8 w-28 text-lg xl:text-2xl flex items-center justify-between">
        {quote.provider && <ProviderLogo name={quote.provider.name} path={quote.provider.path} />}
      </div>
      <span className="text-xs">{availability}</span>
    </header>
  );
  const renderContent = () => (
    <div className="flex-column px-4 py-2 border-b border-gray-100">
      <div className="flex-column justify-start pb-2">
        <span className="text-xs font-medium text-gray-800">Product</span>
        <div className="py-2">
          <div className="text-xs text-gray-700">
            <span className="text-gray-600 text-center w-5 mr-1 inline-block">
              <Icon icon="arrow-down" size={14} />
            </span>
            <FormattedSpeed mbps={formatBandwidth(quote.downloadKbps)} />
            <span> download</span>
          </div>
          <div className="text-xs text-gray-700 mt-2">
            <span className="text-gray-600 text-center w-5 mr-1 inline-block">
              <Icon icon="arrow-up" size={14} />
            </span>
            <FormattedSpeed mbps={formatBandwidth(quote.uploadKbps)} />
            <span> upload</span>
          </div>
        </div>
        <div className="text-xs text-gray-700">
          <span className="text-gray-600 text-center w-5 mr-1 inline-block">
            <Icon icon="wifi" size={14} />
          </span>
          {quote.connectionType && quote.connectionType.name}
        </div>
      </div>
    </div>
  );

  const renderFooter = () => (
    <footer className="flex-col w-full items-start">
      <div className="p-4 flex flex-col w-full">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-col gap-2">
            <span className="text-xs">
              <span className="mr-2">
                <Icon icon="calendar" size={12} />
              </span>
              {quote.contractLengthMonths}-Month contract
            </span>
            <span className="text-xs font-normal">
              <span className="mr-2">
                <Icon icon="wrench" size={12} />
              </span>
              <FormattedMoney amount={quote.installFeeCents / 100} /> Install fee
            </span>
          </div>
          <div className="text-lg flex flex-col align-end font-bold">
            <FormattedMonthlyAmount amount={quote.monthlyFeeCents / 100} />
          </div>
        </div>
      </div>
    </footer>
  );

  const quoteBlockClasses = cx(
    // Padding
    'p-0',

    // Background
    'dark:m-bg-gray-700 bg-white border-t border-gray-100 ',

    // Layout
    'flex flex-col justify-between',
    'xl:flex-col xl:items-start',
  );

  return (
    <div className={quoteBlockClasses}>
      <div className="w-full">{renderHeader()}</div>
      <div className="w-full mt-5 xl:mt-0 xl:flex-3">{renderContent()}</div>
      <div className="w-full mt-3 xl:mt-0 xl:flex-1">{renderFooter()}</div>
    </div>
  );
}

export default ConfirmQuoteBlock;
