import type { UseStorageOptions } from './useStorage';
import { getStorage, useStorage } from './useStorage';

export function getLocalStorage<T>(key: string, initialValue: T, options?: UseStorageOptions<T>) {
  return getStorage(window.localStorage, key, initialValue, options);
}

export function useLocalStorage<T>(key: string, initialValue: T, options?: UseStorageOptions<T>) {
  return useStorage(window.localStorage, key, initialValue, options);
}
